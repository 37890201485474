import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as groupsAnEnMGBKy4Meta } from "/vercel/path0/pages/groups.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as menue28QHjVRIcMeta } from "/vercel/path0/pages/menu.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91item_93zKgFSelgFtMeta } from "/vercel/path0/pages/vacancies/[item].vue?macro=true";
import { default as indexp3qWQtgtPJMeta } from "/vercel/path0/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "groups___nl",
    path: "/groepen",
    component: () => import("/vercel/path0/pages/groups.vue")
  },
  {
    name: "groups___en",
    path: "/en/groups",
    component: () => import("/vercel/path0/pages/groups.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "menu___nl",
    path: "/menus",
    component: () => import("/vercel/path0/pages/menu.vue")
  },
  {
    name: "menu___en",
    path: "/en/menus",
    component: () => import("/vercel/path0/pages/menu.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "vacancies-item___nl",
    path: "/vacatures/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies-item___en",
    path: "/en/vacancies/:item()",
    component: () => import("/vercel/path0/pages/vacancies/[item].vue")
  },
  {
    name: "vacancies___nl",
    path: "/vacatures",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  },
  {
    name: "vacancies___en",
    path: "/en/vacancies",
    component: () => import("/vercel/path0/pages/vacancies/index.vue")
  }
]